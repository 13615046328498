<template>
  <div class="org-show-page">
    <fm-title title-text="党建品牌展示窗"></fm-title>
    <div class="content">
      <div class="item" @click="goDetail(item)" v-for="item in dataList" :key="item.id">
        <org-item :data="item"></org-item>
      </div>
    </div>
  </div>
</template>

<script>
import OrgItem from './show/item'
export default {
  components: {
    OrgItem
  },
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
      let datas = await this.$store.dispatch('loadOrgList')
      datas = datas.map(v => v.data)
      datas.forEach(v => {
        v.otherInfo = v.description ? JSON.parse(v.description) : {}
      })
      this.dataList = datas
    },
    goDetail (item) {
      this.$router.push({
        name: 'hrms.org.detail',
        query: {
          id: item.id
        }
      })
      console.log(item)
    }
  }
}
</script>

<style scoped lang="less">
.org-show-page {
  background: #fff;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  max-height: calc(100% - 20px);
  margin: 10px;
  .content {
    width: calc(100% - 20px);
    height: calc(100% - 68px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px;
    .item {
      width: 350px;
    }
  }
}
</style>
