<template>
  <div class="content org-item">
    <div class="a-img" v-if="imgSrc">
      <img :src="imgSrc"/>
    </div>
    <div class="a-img" v-loading="loadingFile" v-else>未上组织标识</div>
    <div class="title">
      {{orgData.name}}
    </div>
    <div class="c">
      {{orgData.otherInfo.djppmc}}
    </div>
  </div>
</template>

<script>
import {
  fileRequest
} from '@/api'

export default {
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      orgData: {},
      imgSrc: null,
      loadingFile: false
    }
  },
  watch: {
    data: {
      handler () {
        let data = JSON.parse(JSON.stringify(this.data))
        this.orgData = data
        if (this.orgData.otherInfo.logoFileId) {
          this.loadFile()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async loadFile () {
      this.imgSrc = null
      this.loadingFile = true
      try {
        let fileData = await fileRequest.download({
          id: this.orgData.otherInfo.logoFileId
        })
        if (fileData) {
          const src = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this.imgSrc = src
        } else {
          this.imgSrc = null
        }
      } catch (error) {
        this.imgSrc = null
      }
      this.loadingFile = false
    }
  }
}
</script>

<style scoped lang="less">
.org-item {
  cursor: pointer;
  background: #fff;
  box-shadow:2px 2px 6px rgba(0,0,0,0.1);
  overflow-y: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-top: 5px;
  }
  .c {
    width: 100%;
    color: #666;
    text-align: center;
    padding: 5px 10px;
  }
  .label {
    padding-left: 10px;
    min-width: 80px;
    color: #666;
  }
  .info-item {
    display: flex;
  }
  .content {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .a-img {
    height: 166px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #ddd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
</style>
